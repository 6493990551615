import React, { useEffect } from 'react'
import {
  IonModal,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { close, checkmarkSharp, sparklesOutline } from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'
import { GenerateImageModalVM } from '../../view-models/generate-image-modal/GenerateImageModalVM'
import BackIcon from '../../../shared/BackIcon'
import ImagesGrid from '../../../storage-files/views/ImagesGrid'
import ImagesViewer from '../../../storage-files/views/ImagesViewer'
import { AppStore } from '../../../stores/AppStore'

interface Props {
  rootStore?: RootStore
  appStore?: AppStore
  shown: boolean
  parentRecordTable: string
  parentRecordGuid: string
  description: string
  bot: string
  onHide: () => void
}

const GenerateImageModal: React.FC<Props> = ({
  rootStore,
  appStore,
  shown,
  onHide,
  bot,
  parentRecordTable,
  parentRecordGuid,
  description,
}) => {
  const vm = useLocalObservable(
    () => new GenerateImageModalVM(rootStore, parentRecordTable, parentRecordGuid, description, bot, onHide)
  )

  useEffect(() => {
    vm.setDescription(description)
    vm.setShown(shown)
  }, [shown, description])

  useEffect(() => {
    return () => vm.dispose()
  }, [])

  const renderImagesGrid = () => {
    return (
      <ImagesGrid
        columns={2}
        storageFiles={vm.storageFiles}
        onImageClick={(index) => vm.openImagesViewer(index)}
        sortable={false}
      />
    )
  }

  const renderImagesViewer = () => {
    return (
      <ImagesViewer
        key={vm.storageFiles.length}
        storageFiles={vm.storageFiles}
        isOpen={vm.imagesViewerShown}
        onClose={() => vm.closeImagesViewer()}
        initialIndex={vm.imagesViewerIndex}
      />
    )
  }

  return (
    <IonModal
      id='GenerateImageModal'
      isOpen={vm.shown}
      showBackdrop={true}
      // onDidPresent={() => vm.onModalShown()}
      onDidDismiss={() => vm.hide()}
    >
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => vm.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>{vm.modalTitle}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => vm.cancel()}>
              <IonIcon icon={close} />
            </IonButton>
            <IonButton onClick={() => vm.done()}>
              <IonIcon icon={checkmarkSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY ref={(e) => appStore.applyScrollStyles(e)} style={{ height: appStore.listHeightPx }}>
        {renderImagesGrid()}
        {renderImagesViewer()}
      </IonContent>
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          color='tertiary'
          onClick={() => vm.getMoreImages()}
          style={{
            marginTop: '-60px',
            marginLeft: '-60px',
          }}
        >
          <IonIcon icon={sparklesOutline} />
        </IonFabButton>
      </IonFab>
    </IonModal>
  )
}

export default inject('rootStore', 'appStore')(observer(GenerateImageModal))
