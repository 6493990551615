import { action, autorun, computed, makeObservable, observable, reaction, runInAction, when } from 'mobx'
import { StorageFile } from '../aggregate/StorageFile'
import { StorageFilesReadService } from '../services/StorageFilesReadService'
import { RootStore } from '../../stores/RootStore'
import { StorageFileFormatEnum } from '../enums/StorageFileFormatEnum'
import { StorageFileFormat } from '../aggregate/StorageFileFormat'

export class StorageFileImageVM {
  private reactions: Array<() => void> = []
  @observable private storageFile: StorageFile | StorageFileFormat
  private readSvc: StorageFilesReadService

  constructor(private rootStore: RootStore, storageFile: StorageFile | StorageFileFormat) {
    const lg = (storageFile as StorageFile).Formats.find((f) => f.Format === StorageFileFormatEnum.Large)
    if (lg) {
      lg.AvailableOffline = storageFile.AvailableOffline
      storageFile = lg
    }
    this.storageFile = storageFile
    this.readSvc = new StorageFilesReadService()
    this.loadUrl()
    this.loadReactions()
    makeObservable(this)
  }

  @observable public url: string = undefined

  private loadReactions() {
    this.reactions.push(
      reaction(
        () => this.storageFile.Url,
        () => this.loadUrl()
      )
    )
    if (this.rootStore.storageFilesStore.isLoaded) return
    when(
      () => this.rootStore.storageFilesStore.isLoaded,
      () => this.loadUrl()
    )
  }

  @action
  private setUrl(val: string) {
    if (this.url === val) return
    this.url = val
  }

  @action
  private async loadUrl() {
    if (this.storageFile.AvailableOffline) {
      if (this.readSvc.hasCachedImage(this.storageFile)) {
        this.setUrl(this.readSvc.getCacheImage(this.storageFile))
        return
      }
      const url = await this.readSvc.getDataUrl(this.storageFile)
      if (!url) {
        if (this.storageFile.hasSignedUrl) {
          this.setUrl(this.storageFile.Url)
          return
        }
        return
      }
      this.setUrl(url)
      return
    }
    if (!this.rootStore.storageFilesStore.isLoaded) {
      return
    }
    if (!this.storageFile.hasSignedUrl) {
      return
    }
    this.setUrl(this.storageFile.Url)
  }

  public dispose() {
    this.reactions.forEach((e) => e())
  }

  @computed
  public get isLoading() {
    return !this.url
  }
}
