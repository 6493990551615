import React, { useEffect } from 'react'
import {
  IonModal,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { close, checkmarkSharp, mic } from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'
import BackIcon from '../../../shared/BackIcon'
import { AppStore } from '../../../stores/AppStore'
import Content from './Content'
import { BotSessionEditVM } from '../../view-models/edit/BotSessionEditVM'
import { BotSessionEditModalVM } from '../../view-models/edit/BotSessionEditModalVM'

interface Props {
  rootStore?: RootStore
  appStore?: AppStore
  shown: boolean
  title: string
  description: string
  parentRecordTable?: string
  parentRecordGuid?: string
  botName: string
  onHide: () => void
}

const BotSessionEditModal: React.FC<Props> = (props) => {
  const { shown, appStore, title } = props
  const vm = useLocalObservable(() => new BotSessionEditModalVM(props))

  useEffect(() => {
    vm.setShown(shown)
    appStore.setActiveModal('BotSessionEditModal', 56)
  }, [shown])

  return (
    <IonModal id='BotSessionEditModal' isOpen={vm.shown} showBackdrop={true} onDidDismiss={() => vm.hide()}>
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => vm.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <Content {...props} inModal />
    </IonModal>
  )
}

export default inject('rootStore', 'appStore')(observer(BotSessionEditModal))
