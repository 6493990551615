import React from 'react'
import { IonButton, IonIcon, IonItem } from '@ionic/react'
import { RootStore } from '../../../stores/RootStore'
import { inject, observer } from 'mobx-react'
import { cameraOutline, sparklesSharp } from 'ionicons/icons'
import { RecipesStore } from '../../store/RecipesStore'

interface Props {
  rootStore?: RootStore
  recipesStore?: RecipesStore
}

const ImageButtons: React.FC<Props> = ({ rootStore, recipesStore }) => {
  const { editVM } = recipesStore
  if (!editVM) return null

  return (
    <>
      {/* <IonItem> */}
      <div className='buttons'>
        <IonButton onClick={() => editVM.takePhoto()} size='large'>
          <IonIcon icon={cameraOutline} size='small' />
        </IonButton>
        <IonButton onClick={() => editVM.openGenerateImage()} size='large'>
          <IonIcon icon={sparklesSharp} size='small' />
        </IonButton>
      </div>
      {/* </IonItem> */}
    </>
  )
}

export default inject('rootStore', 'recipesStore')(observer(ImageButtons))
