import { RootStore } from '../../stores/RootStore'
import { computed, observable, action, reaction, makeObservable, runInAction } from 'mobx'
import { ListItemRowVM } from './ListItemRowVM'
import { ListItemGroupRowVM } from './ListItemGroupRowVM'
import { IItemSelectableVM } from '../../items-select/interfaces/IItemSelectableVM'
import { ListItemsService } from '../services/ListItemsService'
import { ListItem } from '../aggregate/ListItem'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { Browser } from '@capacitor/browser'
import { PdfOpenService } from '../../shared/pdf/services/PdfOpenService'
import { BotSessionEditVM } from '../../bot-sessions/view-models/edit/BotSessionEditVM'

export class ListItemsListVM
  extends ListVM<RootStore, ListItem, ListItemGroupRowVM, ListItemRowVM, undefined>
  implements IItemSelectableVM
{
  private keepTimeout: NodeJS.Timeout
  private cachedCaptureVoiceVM: BotSessionEditVM

  constructor(rootStore: RootStore) {
    super(rootStore, ListItemRowVM, ListItemGroupRowVM, undefined)
    makeObservable(this)
    this.stickyHeaders = true
    this.autoGrow = true
    this.startCollapsed = false
    this.endOptionsWidth = 78
    this.didScrollOffset = 20
    this.setGroups('categoryName', '')
    this.addReaction(
      reaction(
        () => this.finalListHeight,
        () => this.setHeight(this.finalListHeight),
        { delay: 200 }
      )
    )
    this.addReaction(
      reaction(
        () => this.rootStore.itemsStore.recordJustUpdated,
        () => this.reloadRows()
      )
    )
    this.addReaction(
      reaction(
        () => this.rootStore.listItemsStore.recordJustUpdated,
        () => this.reloadRows()
      )
    )
    this.addReaction(
      reaction(
        () => this.rootStore.categoriesStore.recordJustUpdated,
        () => this.reloadRows()
      )
    )
    this.addReaction(
      reaction(
        () => this.rootStore.boardsStore.currentBoardId,
        () => this.reloadRows()
      )
    )
    this.setHeight(this.finalListHeight)
  }

  @observable public event: any
  @observable public menuShown: boolean = false
  public type: string = 'Shopping List'
  @observable public keepUps: string[] = []
  @observable public keepDowns: string[] = []
  @observable public offlineToastShown: boolean = false
  @observable public clearCompletedConfirmShown: boolean
  @observable public pdfLoading: boolean = false
  @observable public captureVoiceModalShown: boolean = false
  @observable public isCapturingAudio: boolean = false

  private get captureVoiceVM() {
    if (!this.cachedCaptureVoiceVM) {
      console.log('making VM')
      const props = {
        rootStore: this.rootStore,
        botName: 'capture-list-items-voice-v1',
        title: 'Add items by voice',
        description: 'Add items by voice',
        parentRecordTable: 'listitems.ListItem',
      }
      this.cachedCaptureVoiceVM = new BotSessionEditVM(props)
    }
    return this.cachedCaptureVoiceVM
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @computed
  private get finalListHeight() {
    let offset = 0
    if (this.rootStore.appStore.isIos) offset += -2
    return this.rootStore.appStore.listHeight - offset
  }

  protected rowVMSortFunction = (a: ListItemRowVM, b: ListItemRowVM): number => {
    const aVal = a.rank
    const bVal = b.rank
    if (aVal < bVal) return -1
    return 0
  }

  protected getRecords() {
    return this.rootStore.listItemsStore.currentBoardRecords.filter((e) => !e.isCleared)
  }

  @action
  public openMenu(e: React.MouseEvent) {
    e.persist()
    this.event = e
    this.menuShown = true
  }

  @action
  public sync() {
    setTimeout(async () => {
      await this.rootStore.listItemsStore.loadData()
      this.reloadRows()
    }, 500)
    this.hideMenu()
  }

  @action
  public hideMenu() {
    this.menuShown = false
  }

  public getItemQuantity(itemGuid: string) {
    const foundListItem = (this.allRows as ListItemRowVM[]).find((e) => e.itemGuid === itemGuid)
    if (foundListItem) return foundListItem.quantity
    return 0
  }

  public isGotten(itemGuid: string) {
    const foundListItem = (this.allRows as ListItemRowVM[]).find((e) => e.itemGuid === itemGuid)
    if (foundListItem) return foundListItem.isGotten
    return false
  }

  public isCleared(itemGuid: string) {
    const foundListItem = (this.allRows as ListItemRowVM[]).find((e) => e.itemGuid === itemGuid)
    if (foundListItem) return foundListItem.isCleared
    return false
  }

  @action
  public toggleGotten(itemGuid: string) {
    const foundListItem = (this.allRows as ListItemRowVM[]).find((e) => e.itemGuid === itemGuid)
    if (!foundListItem) return
    foundListItem.toggleGotten()
  }

  @action
  public markAsCleared(itemGuid: string) {
    const foundListItem = (this.allRows as ListItemRowVM[]).find((e) => e.itemGuid === itemGuid)
    if (!foundListItem) return
    foundListItem.markAsCleared()
  }

  @action
  public increaseQuantity(itemGuid: string, qty: number = 0) {
    let listItem = this.rootStore.listItemsStore.getByItemGuid(itemGuid)
    if (listItem) {
      const row = (this.allRows as ListItemRowVM[]).find((e) => e.itemGuid === itemGuid)
      if (row) {
        row.increaseQuantity(qty) // do this so that future items can be considered
      } else {
        listItem.increaseQuantity(qty)
      }
    } else {
      console.log('creating new')
      listItem = ListItem.create(this.rootStore.boardsStore.currentBoardId, itemGuid)
      listItem.increaseQuantity(qty)
    }
    const svc = new ListItemsService(this.rootStore)
    svc.save(listItem.toDTO())
  }

  @action
  public decreaseQuantity(itemGuid: string) {
    const foundListItem = (this.allRows as ListItemRowVM[]).find((e) => e.itemGuid === itemGuid)
    if (!foundListItem) return
    foundListItem.decreaseQuantity()
    const svc = new ListItemsService(this.rootStore)
    svc.save(foundListItem.listItem.toDTO())
  }

  @action
  public async removeQuantity(itemGuid: string) {
    const foundListItem = (this.allRows as ListItemRowVM[]).find((e) => e.itemGuid === itemGuid)
    if (!foundListItem) return
    const svc = new ListItemsService(this.rootStore)
    svc.delete(foundListItem.listItem.toDTO())
  }

  public doneAddingItems() {
    this.rootStore.appStore.history.push('/')
  }

  @action
  public addKeepUp(listItemGuid: string) {
    this.keepUps.push(listItemGuid)
  }

  @action
  public addKeepDown(listItemGuid: string) {
    this.keepDowns.push(listItemGuid)
  }

  public resetKeepTimeout() {
    if (this.keepTimeout) clearTimeout(this.keepTimeout)
    this.keepTimeout = setTimeout(() => this.clearKeeps(), 2000)
  }

  @action
  private clearKeeps() {
    if (this.isDown) {
      this.resetKeepTimeout()
      return
    }
    this.keepUps = []
    this.keepDowns = []
    this.reloadRows()
  }

  @action
  public goToCategoriesSetup() {
    this.rootStore.appStore.navigateTo('/categorieslist')
  }

  @action
  public goToItemsSelect(): void {
    this.rootStore.appStore.navigateTo('/itemsselect')
  }

  @action
  public clearCompleted() {
    this.hideMenu()
    const svc = new ListItemsService(this.rootStore)
    svc.clearCompleted()
    this.reloadRows()
  }

  @action
  public async refresh(e) {
    if (window.Offline.state === 'down') {
      this.offlineToastShown = true
      if (!e) return
      setTimeout(() => e.target.complete(), 1000)
      return
    }
    await this.rootStore.listItemsStore.loadData()
    if (!e) return
    setTimeout(() => e.target.complete(), 1000)
  }

  @action
  public hideOfflineToast() {
    this.offlineToastShown = false
  }

  @computed
  public get hasCompletedListItems() {
    return this.getRecords().filter((e) => e.isGotten).length >= 1
  }

  @computed
  public get ptrEnabled(): boolean {
    return this.atTop && this.hasRows && !this.sliding
  }

  @action
  public showClearCompletedConfirm(): void {
    this.clearCompletedConfirmShown = true
  }

  @action
  public hideClearCompletedConfirm(): void {
    this.clearCompletedConfirmShown = false
  }

  @action
  public async pdf() {
    if (this.rootStore.appStore.isAndroidNative) {
      let url = process.env.REACT_APP_API_URL
      url += '/api/listitems/printable?boardGuid='
      url += this.rootStore.boardsStore.currentBoardGuid
      Browser.open({ url })
      return
    }
    this.pdfLoading = true
    let url = process.env.REACT_APP_API_URL
    url += '/api/listitems/pdf?boardGuid='
    url += this.rootStore.boardsStore.currentBoardGuid
    const svc = new PdfOpenService(this.rootStore)
    await svc.openPdf(url)
    runInAction(() => (this.pdfLoading = false))
  }

  public async startAudioCapture() {
    await this.captureVoiceVM.startAudioCapture()
    runInAction(() => (this.isCapturingAudio = true))
  }

  public async stopAudioCapture() {
    await this.captureVoiceVM.stopAudioCapture()
    runInAction(() => (this.isCapturingAudio = false))
    this.openVoiceToTextCaptureModal()
  }

  @action
  public openVoiceToTextCaptureModal(): void {
    this.captureVoiceModalShown = true
  }

  @action
  public hideCaptureVoiceModal(): void {
    this.captureVoiceModalShown = false
  }
}
