import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonRefresher,
  IonRefresherContent,
  IonFabButton,
  IonFab,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import ListItemsListPageMoreMenu from './ListItemsListPageMoreMenu'
import { add, mic } from 'ionicons/icons'
import { ListItemsStore } from 'src/list-items/store/ListItemsStore'
import ListItemsList from './ListItemsList'
import { EvilIcons } from 'react-web-vector-icons'
import ListItemsListPTR from './ListItemsListPTR'
import OfflineToast from '../../../shared/OfflineToast'
import ListItemsListPageContent from './ListItemsListPageContent'

interface Props {
  listItemsStore: ListItemsStore
  appStore: AppStore
  history: any
}

@inject('listItemsStore')
@inject('appStore')
@observer
export default class ListItemsListPage extends React.Component<Props, any> {
  componentDidMount() {
    this.props.appStore.setCurrentRoute('/')
  }

  render() {
    const listVM = this.props.listItemsStore.listVM
    if (!listVM) return null
    return (
      <>
        <IonHeader>
          <IonToolbar />
        </IonHeader>

        <IonHeader id='ListItemsListPage_Header'>
          <IonToolbar color='secondary'>
            <IonTitle>Shopping List</IonTitle>
            <IonButtons slot='end'>
              <ListItemsListPageMoreMenu />
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent id='ListItemsListPage_Content' style={{ height: this.props.appStore.listHeightPx }} scrollY={false}>
          <ListItemsListPageContent />
        </IonContent>

        <IonFab vertical='bottom' horizontal='end'>
          {!this.props.appStore.isSplitPaneVisible && (
            <IonFabButton
              color='tertiary'
              onClick={() => listVM.goToItemsSelect()}
              style={{ marginTop: this.props.appStore.fabOffset }}
            >
              <IonIcon icon={add} />
            </IonFabButton>
          )}
          <IonFabButton
            size='small'
            color='tertiary'
            onMouseDown={() => listVM.startAudioCapture()}
            onMouseUp={() => listVM.stopAudioCapture()}
            onTouchStart={() => listVM.startAudioCapture()}
            onTouchEnd={() => listVM.stopAudioCapture()}
            style={{ marginTop: this.props.appStore.fabOffset }}
          >
            <IonIcon icon={mic} />
          </IonFabButton>
          {this.props.appStore.isSplitPaneVisible && (
            <IonFabButton
              color='tertiary'
              onClick={() => listVM.goToItemsSelect()}
              style={{ marginTop: this.props.appStore.fabOffset }}
            >
              <IonIcon icon={add} />
            </IonFabButton>
          )}
        </IonFab>
      </>
    )
  }
}
