import { observable, action, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'

export class BotSessionEditModalVM {
  private rootStore: RootStore
  private onHide: () => void

  constructor({ rootStore, onHide }: { rootStore?: RootStore; onHide?: () => void }) {
    makeObservable(this)
    this.rootStore = rootStore
    this.onHide = onHide
  }

  @observable public shown: boolean = false

  @action
  public setShown(val: boolean): void {
    this.shown = val
  }

  @action
  public hide() {
    this.shown = false
    this.onHide()
  }

  public goBack(): void {
    this.hide()
  }
}
