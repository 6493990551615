import * as AdaptiveCards from 'adaptivecards'
import { action, computed, makeObservable, observable, runInAction, set, toJS } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { BotSessionEditVM } from './BotSessionEditVM'
import { Interaction } from '../../aggregate/Interaction'
import { AdaptiveCard } from 'adaptivecards'
import { BotSession } from '../../aggregate/BotSession'

export class BotSessionInteractionCardVM {
  private botSession: BotSession
  private interaction: Interaction
  @observable private ionCard: HTMLIonCardElement

  constructor(
    private rootStore: RootStore,
    private parentVM: BotSessionEditVM,
    botSession: BotSession,
    interaction: Interaction
  ) {
    makeObservable(this)
    this.rootStore = rootStore
    this.botSession = botSession
    this.interaction = interaction
    this.loadCardData()
  }

  @observable public height: number = 0
  @observable public renderKey: string = ''
  public adaptiveCardData: any = undefined

  public handleSubmit(adaptiveCard: AdaptiveCard, actionData: any): void {
    let data = {}
    let handled = false
    if (actionData.action === 'saveAnswer') {
      const inputs = adaptiveCard.getAllInputs()
      inputs.forEach((input) => {
        if (input instanceof AdaptiveCards.TextInput) {
          data[input.id] = input.value
        }
        if (input instanceof AdaptiveCards.ChoiceSetInput) {
          data[input.id] = input.value
        }
      })
      this.parentVM.handleSubmit(this.interactionGuid, data)
      handled = true
    }
    if (actionData.action === 'saveRecipe') {
      this.parentVM.handleSaveRecipe(this.interactionGuid)
      handled = true
    }
    if (actionData.action === 'openRecipe') {
      this.parentVM.handleOpenRecipe(this.interactionGuid)
      handled = true
    }
    if (actionData.action === 'toggleListItem') {
      this.parentVM.toggleListItem(actionData.itemGuid)
      handled = true
    }
    if (actionData.action === 'addNewItem') {
      this.parentVM.addNewItem(actionData.itemName, actionData.categoryGuid)
      handled = true
    }
    if (actionData.action === 'saveUrl') {
      this.parentVM.handleSaveUrl(this.interactionGuid, actionData.url)
      handled = true
    }
    if (actionData.action === 'generateMoreImages') {
      this.parentVM.handleGenerateMoreImages()
      handled = true
    }
    if (actionData.action === 'attachImageToRecipe') {
      this.parentVM.handlAttachImageToRecipe(this.interactionGuid)
      handled = true
    }
    if (actionData.action === 'generateRecipeFromPhoto') {
      this.parentVM.handleGenerateRecipeFromPhoto(this.interactionGuid)
      handled = true
    }
    if (actionData.action === 'selectOption') {
      this.parentVM.handleSelectOption(this.interactionGuid, actionData.option)
      handled = true
    }
    if (actionData.action === 'toggleIngredient') {
      this.parentVM.handleToggleIngredient(this.interactionGuid, actionData.option)
      handled = true
    }
    if (actionData.action === 'requestRecipes') {
      this.parentVM.handleRequestRecipes(this.interactionGuid, actionData.other)
      handled = true
    }
    if (actionData.action === 'generateRecipe') {
      this.parentVM.handleGenerateRecipe(this.interactionGuid, actionData.recipeName)
      handled = true
    }
    if (actionData.action === 'makeChangesToRecipe') {
      this.parentVM.handleMakeChangesToRecipe(this.interactionGuid)
      handled = true
    }
    if (actionData.action === 'markAsAnswered') {
      if (!actionData.other) return
      if (actionData.other === '') return
      this.parentVM.handleMarkAsAnswered(this.interactionGuid, actionData.other)
      handled = true
    }
    if (actionData.action === 'saveNewIngredients') {
      this.parentVM.handleMarkAsAnswered(this.interactionGuid, actionData.other)
      handled = true
    }
    if (actionData.action === 'addUploadImageInteraction') {
      this.parentVM.handleAddUploadImageInteraction()
      handled = true
    }
    if (actionData.action === 'openCamera') {
      this.parentVM.handleOpenCamera(this.interactionGuid)
      handled = true
    }
    if (!handled) {
      alert('Unhandled action ' + JSON.stringify(actionData))
      return
    }
    this.increaseRenderKey()
  }

  @action
  private increaseRenderKey() {
    this.renderKey = Math.random().toString()
  }

  @computed
  public get hasNativeElement(): boolean {
    return Boolean(this.ionCard)
  }

  @computed
  public get hasRecipe(): boolean {
    return Boolean(this.interaction.details?.HasRecipe)
  }

  @computed
  public get hasAnswer(): boolean {
    return Boolean(this.interaction.Answer)
  }

  @action
  public setIonCard(e: HTMLIonCardElement): void {
    this.ionCard = e
    setTimeout(() => this.setHeight(this.ionCard.clientHeight), 100)
  }

  @action
  public setHeight(height: number) {
    this.height = height
  }

  public getHeight() {
    if (!this.ionCard) return 0
    const rect = this.ionCard.firstElementChild.getBoundingClientRect()
    // console.log({ rect })
    if (!rect.height) return 0
    return rect.height
  }

  public scrollIntoView() {
    if (!this.ionCard) {
      console.log('ionCard not set to scroll into view')
      // setTimeout(() => this.scrollIntoView(), 100)
      return
    }
    this.ionCard.scrollIntoView()
  }

  @computed
  public get interactionGuid(): string {
    return this.interaction.InteractionGuid
  }

  @action
  private loadCardData(): any {
    let dto = this.interaction.toDTO()
    if (this.botSession.Bot === 'capture-list-items-voice-v1') {
      dto.listItems = this.rootStore.listItemsStore.currentBoardRecords.map((r) => r.toDTO())
    }
    dto.mode = this.rootStore.appStore.isSplitPaneVisible ? 'desktop' : 'mobile'
    const allInteractions = this.botSession.Interactions
    const sortedInteractions = allInteractions
      .slice()
      .sort((a, b) => a.createdDateTimeAsDate.getTime() - b.createdDateTimeAsDate.getTime())
    const firstCard = sortedInteractions[0]
    const lastCard = sortedInteractions[sortedInteractions.length - 1]
    dto.isFirstCard = this.interactionGuid === firstCard.InteractionGuid
    dto.isLastCard = this.interactionGuid === lastCard.InteractionGuid
    this.adaptiveCardData = dto
  }

  public get isLastCard(): boolean {
    return this.adaptiveCardData.isLastCard
  }

  public get isFirstCard(): boolean {
    return this.adaptiveCardData.isLastCard
  }

  public get adaptiveCardTemplate() {
    if (this.interaction.AdaptiveCardJson) {
      return JSON.parse(this.interaction.AdaptiveCardJson)
    }
    const templateName = this.interaction.AdaptiveCardTemplateName
    const template = this.rootStore.adaptivCardTemplatesStore.templates.find((e) => e.id === templateName)
    if (!template) {
      console.log('No template found for ' + templateName)
      return
    }
    return toJS(template.template)
  }

  @computed
  public get recipeGuid(): string {
    return this.interaction.details.RecipeGuid
  }

  @computed
  public get key(): string {
    return this.interactionGuid + '_' + this.renderKey
  }

  @computed
  public get answer(): string {
    if (!this.interaction.AdaptiveCardAnswerJson) return undefined
    return JSON.parse(this.interaction.AdaptiveCardAnswerJson)
  }

  @computed
  public get rank(): number {
    return this.interaction.Rank
  }

  public get isProcessing(): boolean {
    return this.interaction.IsProcessing
  }
}
