import { IReactionDisposer, action, computed, makeObservable, observable, reaction } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { BotSession } from '../../aggregate/BotSession'
import { BotSessionsService } from '../../services/BotSessionsService'
import { StorageFile } from '../../../storage-files/aggregate/StorageFile'
import generateUUID from '../../../utils/UUID'

export class GenerateImageModalVM {
  private reactionDisposers: IReactionDisposer[] = []
  @observable private botSession: BotSession = undefined

  constructor(
    private rootStore: RootStore,
    private parentRecordTable: string,
    private parentRecordGuid: string,
    itemDescription: string,
    private bot: string,
    private onHide: () => void
  ) {
    makeObservable(this)
    this.botSession = this.lookupBotSession()
    if (!this.botSession) this.createBotSession()
    this.botSession.setDescription(itemDescription)
    this.loadReactions()
  }

  @observable public shown: boolean = false
  @observable public imagesViewerShown: boolean = false
  @observable public imagesViewerIndex: number = 0

  private loadReactions() {
    const reaction1 = reaction(
      () => this.rootStore.botSessionsStore.get(this.botSession.BotSessionGuid),
      (botSession) => this.setBotSession(botSession.clone())
    )
    this.reactionDisposers.push(reaction1)
  }

  public dispose() {
    this.reactionDisposers.forEach((e) => e())
  }

  private lookupBotSession() {
    return this.rootStore.botSessionsStore.currentBoardRecords.find(
      (e) => e.ParentRecordTable === this.parentRecordTable && e.ParentRecordGuid === this.parentRecordGuid
    )
  }

  @action
  private createBotSession() {
    this.botSession = BotSession.create(this.rootStore.boardsStore.currentBoardId, this.bot, '')
    this.botSession.setParentRecord(this.parentRecordTable, this.parentRecordGuid)
  }

  public getMoreImages() {
    this.botSession.addPendingInteraction()
    this.botSession.addPendingInteraction()
    const svc = new BotSessionsService(this.rootStore)
    svc.save(this.botSession.toDTO())
  }

  @action
  private setBotSession(botSession: BotSession) {
    this.botSession = botSession
  }

  @action
  public setDescription(val: string) {
    this.botSession.setDescription(val)
  }

  @computed
  public get storageFiles() {
    return this.botSession.Interactions.slice()
      .sort((a, b) => (a.Rank < b.Rank ? -1 : 0))
      .map((e) => {
        let sf = e.StorageFile
        if (!sf) sf = { Url: '', StorageFileGuid: generateUUID() } as StorageFile
        return sf
      }) as StorageFile[]
  }

  @action
  public setShown(val: boolean): void {
    this.shown = val
    if (this.shown && this.botSession.Interactions.length === 0) this.getMoreImages()
  }

  @computed
  public get modalTitle() {
    return 'Generate'
  }

  @action
  public hide() {
    this.shown = false
    this.onHide()
  }

  public done(): void {
    this.hide()
  }

  public cancel(): void {
    this.hide()
  }

  public goBack(): void {
    this.hide()
  }

  @action
  public openImagesViewer(idx: number): void {
    this.imagesViewerIndex = idx
    setTimeout(() => (this.imagesViewerShown = true), 200)
  }

  @action
  public closeImagesViewer(): void {
    this.imagesViewerShown = false
  }
}
