import { MealsStore } from './../meals/store/MealsStore'
import { MealCategoriesStore } from './../meal-categories/store/MealCategoriesStore'
import { ListItemsStore } from '../list-items/store/ListItemsStore'
import { ItemsStore } from '../items/store/ItemsStore'
import { AppStore } from './AppStore'
import { AuthStore } from '../login/store/AuthStore'
import { ActionsStore } from '../actions/store/ActionsStore'
import { CategoriesStore } from '../categories/store/CategoriesStore'
import { BoardsStore } from '../boards/store/BoardsStore'
import { NotificationsStore } from '../notifications/NotificationsStore'
import { RecipesStore } from '../recipes/store/RecipesStore'
import { RecipeCategoriesStore } from '../recipe-categories/store/RecipeCategoriesStore'
import { IItemsStore } from 'src/items/store/IItemsStore'
import { BoardInvitationsStore } from '../board-invitations/store/BoardInvitationsStore'
import * as Sentry from '@sentry/browser'
import { delay } from '../shared/delay'
import { RootStore as ElexiAppRootStore } from '@elexient/elexiapp.bits.shared'
import { UserStore } from '../user/store/UserStore'
import { BotSessionsStore } from '../bot-sessions/store/BotSessionsStore'
import { StorageFilesStore } from '../storage-files/store/StorageFilesStore'
import { AdaptiveCardTemplatesStore } from '../adaptive-cards/store/AdaptiveCardTemplatesStore'

export class RootStore extends ElexiAppRootStore {
  constructor() {
    super()
    this.appStore = this.addStore<AppStore>(AppStore)
    this.authStore = this.addStore<AuthStore>(AuthStore)
    this.boardsStore = this.addStore<BoardsStore>(BoardsStore)
    this.itemsStore = this.addStore<ItemsStore>(ItemsStore)
    this.actionsStore = this.addStore<ActionsStore>(ActionsStore)
    this.listItemsStore = this.addStore<ListItemsStore>(ListItemsStore)
    this.categoriesStore = this.addStore<CategoriesStore>(CategoriesStore)
    this.botSessionsStore = this.addStore<BotSessionsStore>(BotSessionsStore)
    this.userStore = this.addStore<UserStore>(UserStore)
    this.boardInvitationsStore = this.addStore<BoardInvitationsStore>(BoardInvitationsStore)
    this.notificationsStore = this.addStore<NotificationsStore>(NotificationsStore)
    this.recipesStore = this.addStore<RecipesStore>(RecipesStore)
    this.recipeCategoriesStore = this.addStore<RecipeCategoriesStore>(RecipeCategoriesStore)
    this.mealCategoriesStore = this.addStore<MealCategoriesStore>(MealCategoriesStore)
    this.mealsStore = this.addStore<MealsStore>(MealsStore)
    this.storageFilesStore = this.addStore<StorageFilesStore>(StorageFilesStore)
    this.adaptivCardTemplatesStore = this.addStore<AdaptiveCardTemplatesStore>(AdaptiveCardTemplatesStore)
  }

  public itemsStore: IItemsStore
  public listItemsStore: ListItemsStore
  public appStore: AppStore
  public authStore: AuthStore
  public actionsStore: ActionsStore
  public categoriesStore: CategoriesStore
  public boardsStore: BoardsStore
  public userStore: UserStore
  public boardInvitationsStore: BoardInvitationsStore
  public notificationsStore: NotificationsStore
  public recipesStore: RecipesStore
  public recipeCategoriesStore: RecipeCategoriesStore
  public mealCategoriesStore: MealCategoriesStore
  public mealsStore: MealsStore
  public botSessionsStore: BotSessionsStore
  public storageFilesStore: StorageFilesStore
  public adaptivCardTemplatesStore: AdaptiveCardTemplatesStore

  private loading: boolean = false
  private delayMS: number = 0

  public loadData(reason: string, attempts: number = 1) {
    const DEBUG = process.env.REACT_APP_IS_DEV_MODE === '1'
    if (this.loading && reason !== 'user logged in') {
      console.log('loading already')
      return
    }
    if (DEBUG) console.log('loading data: ' + reason + ' attempt: ' + attempts)
    attempts++
    if (attempts === 7) return delay(1)
    if (!this.appStore.isLoggedIn) {
      return delay(2000).then(() => this.loadData(reason, attempts))
    }
    if (process.env.NODE_ENV === 'test') {
      const proms = [this.boardsStore.loadData(), this.itemsStore.loadData(), this.listItemsStore.loadData()]
      return Promise.all(proms)
    }
    const proms = [
      delay(this.getMoreTime(1000)).then(() => {
        const name = 'boards'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.boardsStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'user'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.userStore.loadUser().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'categories'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.categoriesStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'items'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.itemsStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'listitems'
        if (DEBUG) console.time(name)
        return this.listItemsStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'recipecategories'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.recipeCategoriesStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'recipes'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.recipesStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'mealcategories'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.mealCategoriesStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'meals'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.mealsStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'boardinvitations'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.boardInvitationsStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'botsessions'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.botSessionsStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'storagefiles'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.storageFilesStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'notifications'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.notificationsStore.processNotifications().then(() => DEBUG && console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'adaptiveCardTemplates'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.adaptivCardTemplatesStore.loadData().then(() => DEBUG && console.timeEnd(name))
      }),
    ]
    if (DEBUG) console.log('loading all data')
    this.loading = true
    return delay(1000)
      .then(() => Promise.all(proms).then(() => (this.loading = false)))
      .catch((e) => {
        Sentry.captureException(e)
        console.log(e)
      })
  }

  private getMoreTime(ms) {
    this.delayMS = this.delayMS + ms
    return this.delayMS
  }

  public processActions() {
    setInterval(() => this.actionsStore.actionsSvc.processActions(), 8000)
  }
}
