import { StorageFilesReadService } from './StorageFilesReadService'
import { StorageFile } from '../aggregate/StorageFile'
import { StorageFilesWriteService } from './StorageFilesWriteService'
import { RootStore } from '../../stores/RootStore'
import { StorageFileFormatEnum } from '../enums/StorageFileFormatEnum'
import { StorageFileFormat } from '../aggregate/StorageFileFormat'

export class StorageFilesDownloadService {
  private readSvc: StorageFilesReadService
  private writeSvc: StorageFilesWriteService

  constructor(private rootStore: RootStore) {
    this.readSvc = new StorageFilesReadService()
    this.writeSvc = new StorageFilesWriteService(rootStore)
  }

  public async sync() {
    const forDownload = this.rootStore.storageFilesStore.persistedRecords
      .filter((sf) => sf.AvailableOffline)
      .map((e) => e.Formats.find((e) => e.Format === StorageFileFormatEnum.Large))
      .filter((e) => e)
    const existing = await Promise.all(forDownload.map((sf) => this.readSvc.exists(sf)))
    const toDownload = forDownload.filter((_, i) => !existing[i])
    await Promise.all(toDownload.map((sf) => this.download(sf)))
    toDownload.forEach((sf) => sf.markAsDownloaded())
  }

  public async download(storageFile: StorageFile | StorageFileFormat) {
    // const lg = (storageFile as StorageFile).Formats.find((f) => f.Format === StorageFileFormatEnum.Large)
    // if (lg) storageFile = lg
    const response = await fetch(storageFile.Url)
    const blob = await response.blob()
    await this.writeSvc.saveBlobOffline(blob, storageFile.UniqueFileName)
  }
}
